type PriceFormatOptions = {
  currency: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const formatter = (options: PriceFormatOptions) => {
  const { minimumFractionDigits = 0, maximumFractionDigits = 0, currency } = options;
  return Intl.NumberFormat('es-BO', {
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency',
    currency,
  });
};

export default (price: string, options: PriceFormatOptions): string => {
  const parsed = Number(price);
  return Number.isNaN(parsed) ? undefined : formatter(options).format(parsed);
};
