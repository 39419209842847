import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { PropsWithChildren } from 'react';

export const WidthLimiter = ({ children, sx }: PropsWithChildren<{ sx?: SxProps }>) => {
  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
        maxWidth: 1600,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
