import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import Bison from '../components/icons/Bison';
import { concatSx } from '../utils/formatters';
import { colors } from './styles';

const logoStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '1.3rem',
  color: colors.greys.A700,
  userSelect: 'none',
};

type Props = {
  sx?: SxProps;
};

export default ({ sx }: Props) => {
  return (
    <Box sx={concatSx(logoStyles, sx)}>
      <Bison />
      <span>
        <b>HPA</b> | Bolivia
      </span>
    </Box>
  );
};
