import { Box, GlobalStyles } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { WidthLimiter } from '../components/WidthLimiter';
import Footer from './Footer';
import Header from './Header';
import { colors, theme } from './styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const BaseLayout = ({ children, location }: PropsWithChildren<{ location: Location }>) => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <GlobalStyles
        styles={{
          a: {
            color: colors.primary.A600,
            textDecoration: 'none',
          },
          'a:hover, a.active': {
            color: colors.primary.A500,
          },
          'h1,h2,h3': {
            fontWeight: 600,
          },
          h2: {
            fontSize: '1.6rem',
            fontWeight: 600,

            [theme.breakpoints.down('md')]: {
              fontSize: '1.3rem',
            },
          },
        }}
      />
      <Header location={location} />
      <WidthLimiter
        sx={{
          flexGrow: 1,
          '& > *': {
            padding: '1rem',
          },
        }}
      >
        <div>{children}</div>
      </WidthLimiter>
      <Footer />
    </Box>
  );
};

const Wrapper = (props: PropsWithChildren<{ location: Location }>) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'es-BO' }}>
        <title>HPA Bolivia</title>
        <meta name="description" content="Get the vehicles your business needs" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BaseLayout {...props} />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};

export default Wrapper;
