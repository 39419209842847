import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidthLimiter } from '../components/WidthLimiter';
import { useMetadata } from '../context/metadata';
import Logo from './Logo';
import { colors, theme } from './styles';

const rootStyles = {
  padding: '4rem 4rem 3rem 4rem',
  color: 'white',
  background: colors.primary.A900,

  [theme.breakpoints.down('lg')]: {
    padding: '2rem 2rem 1.5rem 2rem',
  },

  '& h3': {
    fontWeight: 600,
    marginBottom: '0.5rem',
  },

  '@media print': {
    display: 'none',
  },
};

const columnsStyles: SxProps = {
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
  '& > *': {
    padding: '0 1rem 2rem 0',
    flexBasis: 'calc(28% - 1rem)',

    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',

      '&:first-of-type': {
        display: 'none',
      },
    },
  },
  '& > *:last-of-type': {
    paddingRight: 0,
    flexBasis: '20%',
  },
};

const reachUsStyles: SxProps = {
  '& > div': {
    display: 'flex',
    alignItems: 'center',
  },
  '& svg': {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
};

const linksStyles: SxProps = {
  '& ul': {
    listStyle: 'none',
    margin: 0,
  },
  '& ul li': {
    marginBottom: '0.3rem',
  },
  '& a': {
    color: 'white',
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
};

const socialsStyles: SxProps = {
  '& > div': {
    display: 'flex',
    alignItems: 'center',
  },
  '& svg': {
    width: '2rem',
    height: '2rem',
  },
  '& a': {
    color: 'white',
    margin: '0.5rem 0.5rem 0 0',
  },
};
const footerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '10rem',
};

export default () => {
  const { t } = useTranslation();
  const { email, phone } = useMetadata();

  return (
    <Box component="footer" sx={rootStyles}>
      <WidthLimiter sx={footerStyles}>
        <Box sx={columnsStyles}>
          <div>
            <Box sx={{ display: 'flex', margin: '0 0 1rem 0' }}>
              <Logo sx={{ color: 'white', fill: 'white' }} />
            </Box>
            {t('common:slogan')}
          </div>
          <Box sx={reachUsStyles}>
            <h3>{t('footer:reach-us')}</h3>
            <div>
              <PhoneEnabledIcon />
              <a style={{ color: 'white' }} href={`tel:${phone}`}>
                {phone}
              </a>
            </div>
            <div>
              <EmailIcon />
              <a style={{ color: 'white' }} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </Box>
          <Box sx={linksStyles}>
            <h3>{t('footer:quick-links')}</h3>
            <ul>
              <li>
                <Link to="/search/" activeClassName="active">
                  {t('glossary:search')}
                </Link>
              </li>
              <li>
                <Link to="/contacts/">{t('glossary:contacts')}</Link>
              </li>
              <li>
                <Link to="/terms/">{t('glossary:terms-and-privacy')}</Link>
              </li>
            </ul>
          </Box>
          <Box sx={socialsStyles}>
            <h3>{t('footer:stay-in-touch')}</h3>
            <div>{t('footer:follow-us')}</div>
            <div>
              <a
                href="https://www.facebook.com/HPA-Bolivia-103825158554200/"
                target="_blank"
                rel="noopener noreferrer"
                title="Linkedin"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.facebook.com/HPA-Bolivia-103825158554200/"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.facebook.com/HPA-Bolivia-103825158554200/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <InstagramIcon />
              </a>
            </div>
          </Box>
        </Box>
        <Box sx={{ paddingTop: '1rem', borderTop: `1px solid ${colors.greys.A400}` }}>
          <div>Copyright © 2021</div>
        </Box>
      </WidthLimiter>
    </Box>
  );
};
