import { createTheme } from '@mui/material/styles';

export const colors = {
  gradient: 'linear-gradient(45deg, #080321 25%, 75%, #7a196d 90%)',
  background: '#f7f6fe',
  danger: {
    A300: '#ffeeeb',
    A500: '#FF7759',
    A600: '#FF5833',
  },
  primary: {
    A100: '#e6efe9',
    A200: '#cce0d2',
    A300: '#99c0a5',
    A350: '#66a178',
    A400: '#00b339',
    A500: '#00A333',
    A600: '#00621E',
    A700: '#00942f',
    A800: '#00310f',
    A900: '#001406',
  },
  greys: {
    A100: '#F4F6F8',
    A200: '#DFE3E8',
    A300: '#C4CDD5',
    A400: '#8996a4',
    A500: '#637381',
    A550: '#546e7a',
    A600: '#454F5B',
    A700: '#212B36',
  },
};

export const monospaceFontFamily = '"Fira Code", "Courier New", Courier, monospace';

export const theme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.primary.A700,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
          color: 'white',
          backgroundColor: colors.primary.A500,
        },
        outlined: {
          color: colors.primary.A500,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
        rounded: {
          borderRadius: '10px',
        },
        elevation1: {
          boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: colors.background,
          },
          '&$hover': {
            '&:hover': {
              backgroundColor: colors.background,
            },
          },
        },
      },
    },
  },
  palette: {
    divider: colors.greys.A500,
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary.A500,
      dark: colors.primary.A700,
      light: colors.primary.A400,
      contrastText: colors.primary.A100,
    },
    secondary: {
      main: '#fff',
    },
    text: {
      primary: colors.greys.A700,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightBold: 600,
  },
});
