import { PhoneEnabled as PhoneEnabledIcon } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import { graphql, useStaticQuery } from 'gatsby';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidthLimiter } from '../components/WidthLimiter';
import Logo from './Logo';
import { colors, theme } from './styles';

const menuStyles: SxProps = {
  display: 'flex',
  listStyle: 'none',
  fontSize: '1rem',
  '& a': {
    color: colors.greys.A700,
    fontWeight: 600,
    display: 'block',
    padding: '0.8rem 0',
    fontSize: '1.2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0.5rem',
      fontSize: '1rem',
    },
  },
  '& > li': {
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      margin: '0 1rem 0rem 2rem',
    },
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
};
const onlyMobile: SxProps = {
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
};

const contactsStyles: SxProps = {
  textAlign: 'right',
  color: colors.greys.A400,
  paddingRight: '0.5rem',
  margin: '1rem 0 1rem 2rem',
  display: 'flex',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'start',
  },

  '& > * + *': {
    marginLeft: '32px',

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: '1.2rem',
    },
  },
};

const contactStyles: SxProps = {
  display: 'grid',
  gap: '8px',
  gridTemplateColumns: 'auto auto',
  gridTemplateRows: '1fr',
  alignItems: 'center',
};
const NavigationMenu = () => {
  const { t, i18n } = useTranslation('glossary');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',

        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'start',
        },
      }}
    >
      <Box component="ul" sx={menuStyles}>
        <li>
          <Link to="/search/" activeClassName="active">
            {t('search')}
          </Link>
        </li>
        <li>
          <Link to="/contacts/">{t('contacts')}</Link>
        </li>
        <Box component="li" sx={onlyMobile}>
          <Link to="/terms/">{t('terms-and-privacy')}</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ({ location }: { location: Location }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const {
    site: {
      siteMetadata: { email, phone },
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          email
          phone
        }
      }
    }
  `);

  useEffect(() => {
    setMenuIsOpen(false);
  }, [location]);

  return (
    <Box
      component="header"
      sx={{
        zIndex: 1,
        background: 'white',
        boxShadow: `0 0 5px rgba(0,0,0,0.5)`,
        padding: '0.8rem 1rem',

        '@media print': {
          display: 'none',
        },
      }}
    >
      <WidthLimiter sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'none',
              [theme.breakpoints.down('md')]: {
                display: 'block',
              },
            }}
          >
            <IconButton onClick={() => setMenuIsOpen(true)} aria-label="Menu" size="large">
              <MenuIcon />
            </IconButton>
            {isSmallScreen && (
              <>
                <Drawer
                  sx={{
                    '& .MuiDrawer-paper': {
                      minWidth: '20rem',
                      paddingRight: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    },
                  }}
                  open={menuIsOpen}
                  onClose={() => setMenuIsOpen(false)}
                >
                  <Link to="/">
                    <Logo sx={{ margin: '2rem' }} />
                  </Link>
                  <NavigationMenu />
                  <Box sx={contactsStyles}>
                    <Box sx={contactStyles}>
                      <PhoneEnabledIcon /> <a href={`tel:${phone}`}>{phone}</a>
                    </Box>
                    <Box sx={contactStyles}>
                      <EmailIcon /> <a href={`mailto:${email}`}>{email}</a>
                    </Box>
                  </Box>
                </Drawer>
              </>
            )}
          </Box>
          <Link to="/">
            <Logo
              sx={{
                [theme.breakpoints.down('md')]: {
                  marginLeft: '0.5rem',
                  '& svg': {
                    display: 'none',
                  },
                },
              }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        >
          {!isSmallScreen && (
            <Box sx={contactsStyles}>
              <Box sx={contactStyles}>
                <PhoneEnabledIcon />
                <a href={`tel:${phone}`}>{phone}</a>
              </Box>
              <Box sx={contactStyles}>
                <EmailIcon />
                <a href={`mailto:${email}`}>{email}</a>
              </Box>
            </Box>
          )}
          {!isSmallScreen && <NavigationMenu />}
        </Box>
      </WidthLimiter>
    </Box>
  );
};
